const routerPath = {
  HOME: '/',
  LOGIN: '/login',
  MY: '/my',

  // 대시보드
  DASHBOARD_DASHBOARD: '/dashboard',
  // --- service ---
  // 문의관리
  SERVICE_INQUIRY_LIST: '/service/inquiry/list',
  SERVICE_INQUIRY_DETAIL: '/service/inquiry/detail/:id',
  // 회원관리
  SERVICE_USER_SEARCH_LIST: '/service/user/search/list',
  SERVICE_USER_PLUS_LIST: '/service/user/plus/list',
  SERVICE_USER_LEAVE_LIST: '/service/user/leave/list',
  SERVICE_USER_SEARCH_DETAIL: '/service/user/search/detail/:id',
  SERVICE_USER_PLUS_DETAIL: '/service/user/plus/detail/:id',
  SERVICE_USER_LEAVE_DETAIL: '/service/user/leave/detail/:id',
  // 멤버십 관리
  SERVICE_MEMBERSHIP_PAYMENTS_LIST: '/service/membership/payments',
  // 카드관리
  SERVICE_CARD_SEARCH: '/service/card/search',
  SERVICE_CARD_LIST: '/service/card/list',
  SERVICE_CARD_DETAIL: '/service/card/detail/:id',
  // 약관관리
  SERVICE_TERM_LIST: '/service/term/list',
  SERVICE_TERM_DETAIL: '/service/term/detail/:type/:id',
  SERVICE_TERM_REGISTER: '/service/term/register/:type',
  SERVICE_TERM_MODIFY: '/service/term/modify/:type/:id',
  // FAQ관리
  SERVICE_FAQ: '/service/faq',
  SERVICE_FAQ_REGISTER: '/service/faq/register',
  SERVICE_FAQ_MODIFY: '/service/faq/modify/:id',
  // 포인트관리
  SERVICE_POINT_INDIVIDUAL: '/service/point/individual/reward',
  SERVICE_POINT_REWARD: '/service/point/reward',
  SERVICE_POINT_HISTORY: '/service/point/history',
  // 입금이체 은행관리
  SERVICE_BANK: '/service/bank',
  // 바코드 관리
  SERVICE_BARCODE_MAPPING: '/service/barcode',

  // --- app ---
  // 점검관리
  APP_INSPECTION: '/app/inspection',
  // 업데이트관리
  APP_UPDATE_LIST: '/app/update/list',
  APP_UPDATE_DETAIL: '/app/update/detail/:id',
  APP_UPDATE_REGISTER: '/app/update/register',
  APP_UPDATE_MODIFY: '/app/update/modify/:id',
  // 알림
  APP_ALARM_LIST: '/app/alarm/list',
  APP_ALARM_DETAIL: '/app/alarm/detail/:status/:id',
  APP_ALARM_REGISTER: '/app/alarm/register',
  APP_ALARM_MODIFY: '/app/alarm/modify/:status/:id',
  // 베너관리
  APP_BANNER_LIST: '/app/banner/list',
  APP_BANNER_DETAIL: '/app/banner/detail/:id',
  APP_BANNER_REGISTER: '/app/banner/register',
  APP_BANNER_MODIFY: '/app/banner/modify/:id',

  // --- cs ---
  // 회원조회
  CS_USER_BYUSERDATA_LIST: '/cs/user/by-user-data/list',
  CS_USER_BYBANK: '/cs/user/by-bank',
  CS_USER_BYAPPROVE: '/cs/user/by-approve',
  CS_CARDRETURN_LIST: '/cs/card-return',
  CS_KBCARDPURCHASE_LIST: '/cs/kb-card-purchase',
  CS_TAXRETURN_LIST: '/cs/tax-return',
  CS_BLACKLIST_SEARCH: '/cs/blacklist/search',


  // --- store ---
  // 가맹점관리
  STORE_STORE_LIST: '/store/store/list',
  STORE_STORE_REGISTER: '/store/store/register',
  STORE_STORE_MODIFY: '/store/store/modify/:id',
  // 가맹 계약 변경요청
  STORE_CONTRACT_LIST: '/store/contract/list',
  // 주문 및 결제관리
  STORE_ORDER_LIST: '/store/order/list',
  // 개별 거래 조회
  STORE_TRADE_LIST: '/store/trade/list',
  // 정산관리
  STORE_SETTLE_LIST: '/store/settle/list',
  STORE_SETTLE_DETAIL: '/store/settle/detail/:step/:id',

  // --- landing ---
  // 출시알림 이벤트 통계
  LANDING_STATISTIC: '/landing/statistic',
  // 레몬머니레터
  LANDING_LETTER_LIST: '/landing/letter/list',
  LANDING_LETTER_DETAIL: '/landing/letter/detail/:id',
  LANDING_LETTER_REGISTER: '/landing/letter/register',
  LANDING_LETTER_MODIFY: '/landing/letter/modify/:id',
  // 공지사항
  LANDING_NOTICE_LIST: '/landing/notice/list',
  LANDING_NOTICE_DETAIL: '/landing/notice/detail/:id',
  LANDING_NOTICE_REGISTER: '/landing/notice/register',
  LANDING_NOTICE_MODIFY: '/landing/notice/modify/:id',

  // --- admin ---
  // 직원관리
  ADMIN_MEMBER_LIST: '/admin/member/list',
  ADMIN_MEMBER_DETAIL: '/admin/member/detail/:id',
  ADMIN_MEMBER_REGISTER: '/admin/member/register',
  ADMIN_MEMBER_MODIFY: '/admin/member/modify/:id',
  // 시스템 로그
  ADMIN_SYSTEM_LIST: '/admin/system/list',
  // 데이터 조회
  ADMIN_DB_LIST: '/admin/db/list',

  //  --- 모니터링 ---
  MONITORING_AML: '/monitoring/aml',

  //  --- guide ---
  GUIDE_COMPONETS_FORMS: '/guide/components/forms',
  GUIDE_COMPONETS_TAB: '/guide/components/tab',
  GUIDE_COMPONETS_SEARCH: '/guide/components/search',
  GUIDE_COMPONETS_BOARD: '/guide/components/board',
  GUIDE_COMPONETS_TABLE: '/guide/components/table',
  GUIDE_COMPONETS_CATEGORY: '/guide/components/category',
  GUIDE_COMPONETS_CALENDAR: '/guide/components/calendar',
  GUIDE_COMPONETS_BUTTONS: '/guide/components/buttons',
  GUIDE_COMPONETS_BADGES: '/guide/components/badges',
  GUIDE_COMPONETS_WIDGETS: '/guide/components/widgets',
  GUIDE_COMPONETS_STEP: '/guide/components/step',
  GUIDE_ICONS: '/guide/icons',
  GUIDE_CHARTS: '/guide/charts',
  GUIDE_LAYER: '/guide/layer',
};

export default routerPath;