export const chartColorData = {
  primary: '#00CA2C',
  lightPurple: '#A194FF',
  purple: '#A785EE',
  blue: '#86BEFF',
  darkBulue: '#216bd9',
  green: '#6CDFC3',
  lightRed: '#FFA8A1',
  red: '#F04848',
  yellow: '#FFD82A',
  darkYellow: '#FFBE70',
}